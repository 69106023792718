// src/Banner.js
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from './Kccb-playstore.png';
import logo2 from './AppleStoreqr.png';
import { FaApple } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";

const BannerItem = () => {
const navigate = useNavigate();

  return (
    <div className='bannerDivv'>
      <Fragment>
          <div className='banner-top'>
              <div className='banner'>
                  <div className='container'>
                      <div className="row align-items-center">
                          <div className="col-12">
                              <div className="banner-wrapper">
                                  <div className="banner-text">
                                      <h4 className='text-white'>KCCB MOBILE APP</h4>
                                      <h1>WELCOME TO KCCB</h1>
                                  </div>
                                  <div className=''>
                                      <button className='btn btn-info' onClick={() => navigate('/send-delete-request')}>SEND DELETE REQUEST</button>
                                  </div>
                                  <div className='mt-5 row'>
                                    <p className='col-lg-6'>
                                        <img className="mx-auto w-50" src={logo} alt="" />
                                        <p className='text-white fs-3'><IoLogoAndroid /> Play Store Apps</p>
                                        
                                    </p>
                                    <p className='col-lg-6'>
                                        <img className="mx-auto w-50" src={logo2} alt="" />
                                        <p className='text-white fs-3'> <FaApple /> Apple Store Apps</p>
                                    </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </Fragment>
    </div>
  );
};

export default BannerItem;
