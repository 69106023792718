import React, { useEffect, useRef, useState } from "react";
import "./today.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import NoSleep from 'nosleep.js';

const TodayBookings = () => {
  const [allBookings, setAllBookings] = useState([]);
  const dt = useRef(null);

  const now = new Date();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []); 


  useEffect(() => {
    const noSleep = new NoSleep();
    noSleep.enable();
    return () => {
      noSleep.disable();
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });


  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  const bill1 = (p) => {
    
    return (
      <>
        <div className="bill">
          {
            p?.bill1?.name !== "" ? (
             <>
              <Avatar image={p?.bill1?.image} size="large" shape="circle" />
          <div>
            <p className="name">{p?.bill1?.name}</p>
            <span className="guest">Guest - {p?.bill1?.guest}</span>
          </div></>
            ) : (
              <>
              <svg width="12" height="35" viewBox="0 0 12 35" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1.248 22.864V20.032H10.176V22.864H1.248Z" fill="white"/>
              </svg>
              </>
            )
          }
          </div>
      </>
    );
  };

  const bill2 = (p) => {
    
    return (
      <>
        <div className="bill">
          {
            p?.bill2?.name !== "" ? (
             <>
              <Avatar image={p?.bill2?.image} size="large" shape="circle" />
          <div>
            <p className="name">{p?.bill2?.name}</p>
            <span className="guest">Guest - {p?.bill2?.guest}</span>
          </div></>
            ) : (
              <>
              <svg width="12" height="35" viewBox="0 0 12 35" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1.248 22.864V20.032H10.176V22.864H1.248Z" fill="white"/>
              </svg>

              </>
            )
          }
          </div>
      </>
    );
  };

  const bill3 = (p) => {
    
    return (
      <>
        <div className="bill">
          {
            p?.bill3?.name !== "" ? (
             <>
              <Avatar image={p?.bill3?.image} size="large" shape="circle" />
          <div>
            <p className="name">{p?.bill3?.name}</p>
            <span className="guest">Guest - {p?.bill3?.guest}</span>
          </div></>
            ) : (
              <>
              <svg width="12" height="35" viewBox="0 0 12 35" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M1.248 22.864V20.032H10.176V22.864H1.248Z" fill="white"/>
              </svg>

              </>
            )
          }
          </div>
      </>
    );
  };

  const bill4 = (p) => {
    
    return (
      <>
        <div className="bill">
          {
            p?.bill4?.name !== "" ? (
             <>
              <Avatar image={p?.bill4?.image} size="large" shape="circle" />
          <div>
            <p className="name">{p?.bill4?.name}</p>
            <span className="guest">Guest - {p?.bill4?.guest}</span>
          </div></>
            ) : (
              <>
              <svg width="12" height="35" viewBox="0 0 12 35" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1.248 22.864V20.032H10.176V22.864H1.248Z" fill="white"/>
              </svg>

              </>
            )
          }
          </div>
      </>
    );
  };

  const meeting = (p) => {
    
    return (
      <>
        <div className="bill">
          {
            p?.Meeting?.name !== "" ? (
             <>
              <Avatar image={p?.Meeting?.image} size="large" shape="circle" />
          <div>
            <span className="name">{p?.Meeting?.name}</span>
            <span className="guest">Guest - {p?.Meeting?.guest}</span>
          </div></>
            ) : (
              <>
              <svg width="12" height="35" viewBox="0 0 12 35" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M1.248 22.864V20.032H10.176V22.864H1.248Z" fill="white"/>
              </svg>

              </>
            )
          }
          </div>
      </>
    );
  };

  const getCategory = () => {
    fetch(`https://kccb.kvillagebd.com/api/v1/booking/get/todays-all-bookings`)
      .then((res) => res.json())
      .then((data) => {
        setAllBookings(data);
      });
  };

  useEffect(() => {
    getCategory();
    const intervalId = setInterval(getCategory, 1 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  console.log(allBookings);

  return (
    <section>

      <div className="header">
        <div className="date-time">
          <h1>{formattedTime}</h1>
          <h2>{formattedToday}</h2>
        </div>
      </div>

      <div>
        <DataTable
          ref={dt}
          value={allBookings}
          dataKey="_id"
          globalFilterFields={["name", "time"]}
          emptyMessage="No Bookings Found."
          scrollable
          stripedRows
          removableSort
          
        >
          <Column
            field="serial"
            header="SL"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            className="sl"
            style={{ width: "250px" }}
          ></Column>

          <Column
            field="time"
            header="Book Time"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            style={{ width: "250px" }}
          ></Column>

          <Column
            field="bill1"
            header="Billiard 1"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white",  placeItems: "center", }}
            body={bill1}
            style={{ width: "250px" }}
          ></Column>

          <Column
            field="bill2"
            header="Billiard 2"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            style={{ width: "250px" }}
            body={bill2}
          ></Column>

          <Column
            field="bill3"
            header="Billiard 3"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            style={{ width: "250px" }}
            body={bill3}
          ></Column>

          <Column
            field="bill4"
            header="Billiard 4"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            style={{ width: "250px" }}
            body={bill4}
          ></Column>

          <Column
            field="Meeting"
            header="Meeting Room"
            headerStyle={{ backgroundColor: "#1A1C33", color: "white" }}
            style={{ width: "250px" }}
            body={meeting}
          ></Column>

        </DataTable>
      </div>

    </section>
  );
};

export default TodayBookings;
